var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "unit-card column",
      style: _vm.unitCardStyles,
      on: {
        click: function($event) {
          _vm.goToLessonsDashboard()
        }
      }
    },
    [
      _c("img", { staticClass: "card-image", attrs: { src: _vm.image } }),
      _c("span", { staticClass: "unit-number" }, [
        _vm._v(_vm._s(_vm.unit.number))
      ]),
      _c("div", { staticClass: "unit-progress" }, [
        _c("div", { style: { width: _vm.unit.progress + "%" } })
      ]),
      _c("div", { staticClass: "unit-content" }, [
        _c(
          "span",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.name || "",
                expression: "name || ''"
              }
            ]
          },
          [_vm._v(_vm._s(_vm.name))]
        ),
        _vm.showStatus
          ? _c("span", { staticClass: "circle", class: _vm.unit.status }, [
              _c("div", { class: _vm.statusIcon })
            ])
          : _vm._e(),
        _c("span", { staticClass: "unit-period" }, [
          _vm._v(_vm._s(_vm.unit.period))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }