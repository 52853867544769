var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unit-dashboard flex-container" },
    _vm._l(_vm.units, function(unit) {
      return _c("UnitCard", { key: unit.id, attrs: { unit: unit } })
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }