<template>
    <div class="unit-dashboard flex-container">
        <UnitCard  v-for="unit of units"
             :key="unit.id" :unit="unit"/>    
    </div>
</template>

<script>
import UnitCard from '@/components/UnitCard.vue'
export default {
    name: 'UnitsDashboard',
    components:{
        UnitCard
    },
    created(){
        //this attribute will allow to define if units should 
        // loaded from back office section or units for student view
        let isBackOfficeSection = this.$route.path.includes('back-office') ?
                                        true : false
        this.$store.commit('updateLoadingStatus', true)
        this.$store.dispatch('loadUnits', isBackOfficeSection)
            .then(() => this.$store.commit('updateLoadingStatus', false))
            .catch(() => this.$store.commit('updateLoadingStatus', false))
    },
    computed:{
        units(){
            return this.$store.state.units 
        }
    },
    mounted(){
        this.startTrackingPage({ sectionName: "Units Dashboard" });
        this.$store.commit("setAnalyzedManagementSection", "Units Dashboard");
    },
    beforeDestroy(){
        this.$store.commit("setAnalyzedManagementSection", "");
        this.stopTrackingPage({ sectionName: "Units Dashboard" });
    }
}
</script>

<style lang="less" scoped>

.unit-dashboard{
    margin: 0 auto;
    max-width: 1024px;
    width: 100%;
    margin-top: 80px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

    .unit-card{
        margin-top: 8px;
        margin-left: 16px;
    }
}
</style>